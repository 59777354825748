body {
  margin: 0;
}

p {
  margin: 0;

}

#chatroom {
  height:485px;
  padding-top: 15px;
  padding-left: 15px;
}

.App {

  display: flex;
  align-items: center;
  justify-content: center;
}

.chatroom {
  width: 335px;
  height: 485px;
  background-color: #FFFFFF;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 8px 0 rgba(0,0,0, 0.3);
}

.chatroom h3 {
  background-color: #4A4A4A;
  color: #FFF;
  margin: 0;
  height: 40px;
  font-size: 20px;
  display: flex; 
  align-items: center;
  justify-content: center;
}

.chatroom .chats {
  box-sizing: border-box;
  padding: 0 20px;
  margin: 10px 0 0;
  height: 395px;
  overflow-y: scroll;
}

.chatroom .chats .chat {
  background: #EDEDED;
  position: relative;
  padding: 5px 13px;
  font-size: 14px;
  border-radius: 10px;
  list-style: none;
  float: left;
  clear: both;
  margin: 10px 0;
  width: 250px;
  overflow-wrap: break-word;
}



.chatroom .chats .chat img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  left: 0px;
}

.chatroom .chats .chat.right {
  float: right;
  clear: both;
  background: #E8F6F8;
}

.chatroom .chats .chat a {
  text-decoration: none;
  color:#3498DB;
}

.translatedMessage {
  text-decoration: none;
  color: red;
}

.chatroom .input {
  height: 40px;
  width: 100%;
  background: #4A4A4A;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chatroom .input input[type="text"] {
  background: #F2F2F2;
  outline: 0;
  border: 2px solid #4A4A4A;
  padding: 0 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-left: 10px;
  height: 30px;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
}

.chatroom .input input[type="submit"] {
  background: transparent;
  border: 0px solid #ffffff;
  height: 30px;
  width: 70px;
  color: #ffffff;
  font-size: 16px;
  box-sizing: border-box;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.chatroom .input input[type="submit"]:hover {
  background: #4A4A4A;
  color: #FFF;
}